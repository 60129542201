<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>赛程管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-steps :active="active" finish-status="success">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
          <el-step title="步骤 4"></el-step>
        </el-steps>
        <div class="opeBox">
          <template v-if="this.active === 1">
            <el-select v-model="team1_name" placeholder="请选择主场战队">
              <el-option
                v-for="item in allTeam"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-select v-model="team2_name" placeholder="请选择客场战队">
              <el-option
                v-for="item in allTeam"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else-if="this.active === 2">
            <el-date-picker
              v-model="startTime"
              type="datetime"
              placeholder="选择比赛开始日期"
            >
            </el-date-picker>
            <el-date-picker
              v-model="closeTime"
              type="datetime"
              placeholder="选择比赛结束日期"
            >
            </el-date-picker>
          </template>
          <template v-else-if="this.active === 3">
            <el-select v-model="referee_value" placeholder="请选择裁判">
              <el-option
                v-for="item in referee"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select v-model="commentary1_value" placeholder="请选择解说1">
              <el-option
                v-for="item in commentary1"
                :key="item.chinaname"
                :label="item.chinaname"
                :value="item.chinaname"
              >
              </el-option>
            </el-select>
            <el-select v-model="commentary2_value" placeholder="请选择解说2">
              <el-option
                v-for="item in commentary2"
                :key="item.chinaname"
                :label="item.chinaname"
                :value="item.chinaname"
              >
              </el-option>
            </el-select>
            <el-select v-model="instructor_value" placeholder="请选择导播">
              <el-option
                v-for="item in instructor"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <div class="btn_wrap">
            <el-button
              v-if="active !== 1"
              type="primary"
              style="margin-top: 12px"
              @click="retry"
              ><i class="el-icon-caret-left"></i>上一步</el-button
            >
            <el-button
              v-if="active !== 4"
              type="primary"
              style="margin-top: 12px"
              @click="next"
              ><i class="el-icon-caret-right"></i>下一步</el-button
            >
            <el-button
              v-if="active === 4"
              type="warning"
              style="margin-top: 12px"
              v-loading="btnloading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              @click.native="submit"
              >发布</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 赛程表 -->
    <el-row>
      <schedule-table />
    </el-row>
  </div>
</template>

<script>
import { pushSchedule,getUserRoles } from "../../api/schedule/index";
import { getEnrollList } from "../../api/enroll/index";
export default {
  name: "",
  data() {
    return {
      active: 1,
      allTeam: [],
      team1_name: "",
      team2_name:'',
      startTime: "",
      closeTime: "",
      btnloading: false,
      scheduleData: [],
      referee: [{ label: "落梨", value: "落梨" }], //裁判
      referee_value: "",
      commentary1: [], //解说1
      commentary1_value: "",
      commentary2: [], //解说1
      commentary2_value: "",
      instructor: [
        { label: "PLFJY", value: "PLFJY" },
        { label: "星宇", value: "星宇" },
        { label: "晓月", value: "晓月" },
      ], //导播
      instructor_value: "",
    };
  },
  components: {
    ScheduleTable: () => import("./components/ScheduleTable.vue"),
  },
  methods: {
    next() {
      if (this.active === 1 || this.active === 2) {
        this.active++;
      } else if (this.active === 3) {
        this.active++;
      } else {
        return;
      }
    },
    retry() {
      if (this.active === 1) {
        return;
      } else {
        this.active--;
      }
    },
    // 发布
    submit() {
      (this.btnloading = true), this.setSchedule();
      setTimeout(() => {
        this.btnloading = false;
      }, 2000);
    },
    setSchedule() {
      console.log("时间：", this.startTime);
      pushSchedule(
        this.team1_name,
        this.team2_name,
        this.startTime,
        this.closeTime
      )
        .then((res) => {
          this.$message.success(res.data);
        })
        .catch((err) => {
          this.$message.error("设置失败！");
          console.log(err);
        });
    },
    // 获取战队信息
    initTeamName() {
      getEnrollList(1,100).then((res) => {
        console.log(res)
        this.allTeam = res.data;
      })
      .catch(err=>{
        console.log(err)
      })
    },
    //获取解说列表
    initGetCommentary(){
      let params = {
        opname: "Commentator"
      }
      getUserRoles(params)
      .then((res)=>{
        this.commentary1=res.data
        this.commentary2=res.data
      })
      .catch(err=>{
        console.log(err)
      })
    }
    //获取裁判列表
  },
  mounted(){
    this.initTeamName(1,5)
    this.initGetCommentary()
  }
};
</script>

<style scoped lang="less">
.el-row {
  margin: 20px;
}
.el-input {
  width: 20%;
  margin: 10px;
}
.el-select {
  margin: 10px;
}
.btn_wrap {
  float: right;
}
</style>
