import request from '@/utils/request'
export function pushSchedule(team1_name,team2_name,opentime,closetime){
    return request.post('/api/v1/admin/game',{
        team1_name,
        team2_name,
        opentime,
        closetime
    })
}
//获取赛程
export function getSchedule(page,pagesize){
    return request.get(`/api/v1/game?page=${page}&page_long=${pagesize}`)
}

//删除赛程
export function delSchedule(id){
    return request.delete(`/api/v1/admin/game?gameid=${id}`)
}
//根据职位获取用户列表 opname
export function getUserRoles(params){
    return request({
        url:'/api/v1/getuserbyop',
        method:'get',
        params,
    })
}
//抽取赛程
export function ballotSchedule(){
    return request.post('/api/v1/admin/team')
}
//修改赛程信息
export function updateSchedule(gameid,info){
    return request.put(`/api/v1/admin/game?gameid=${gameid}`,info)
}